.App {
  text-align: center;
}

.App-body {
  text-align: left;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
}

.App-logo-small {
  height: 25vmin;
  pointer-events: none;
  padding-bottom: 1vmin;
}

/*Logo 旋轉動畫*/
/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/
.App-header {
  background-color: #11141a;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Question-box {
  margin: 15px 10px 15px 15%;
  width: 75%;
  border: 3px solid green;
  padding: 10px;
}

.Question-text {
  font-size: calc(10px + 1vmin);
  text-align: left;
  font-weight: Bold;
}

.Question-option {
  padding: 10px;
  font-style: italic;
  text-align: left;
}

.RewardPage {
  height: 100%;
  width: 70%;
  background-image: url("./resources/passed_CEH_Bg.jpg");
  background-color: #cccccc;
  background-size: auto;
  border: 2px solid black;
  padding: 25px;
  background-repeat: no-repeat;
  text-align: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  padding: 50px;
}

/* Solid Social Share Buttons */

.btn-social,
.btn-social:visited,
.btn-social:focus,
.btn-social:hover,
.btn-social:active {
  color: #ffffff;
  text-decoration: none;
  transition: opacity 0.15s ease-in-out;
}

.btn-social:hover,
.btn-social:active {
  opacity: 0.75;
}

.btn-fb {
  background-color: #3b5998;
}

.btn-tw {
  background-color: #1da1f2;
}

.btn-in {
  background-color: #0077b5;
}

.btn-gp {
  background-color: #db4437;
}

.btn-rd {
  background-color: #ff4500;
}

.btn-line {
  background-color: #00b900;
}

/* Outline Social Share Buttons */

.btn-social-outline {
  background-color: transparent;
  background-image: none;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.btn-fb-outline {
  color: #3b5998;
  border-color: #3b5998;
}

.btn-fb-outline:hover,
.btn-fb-outline:active {
  color: #ffffff;
  background-color: #3b5998;
}

.btn-tw-outline {
  color: #1da1f2;
  border-color: #1da1f2;
}

.btn-tw-outline:hover,
.btn-tw-outline:active {
  color: #ffffff;
  background-color: #1da1f2;
}

.btn-in-outline {
  color: #0077b5;
  border-color: #0077b5;
}

.btn-in-outline:hover,
.btn-in-outline:active {
  color: #ffffff;
  background-color: #0077b5;
}

.btn-gp-outline {
  color: #db4437;
  border-color: #db4437;
}

.btn-gp-outline:hover,
.btn-gp-outline:active {
  color: #ffffff;
  background-color: #db4437;
}

.btn-rd-outline {
  color: #ff4500;
  border-color: #ff4500;
}

.btn-rd-outline:hover,
.btn-rd-outline:active {
  color: #ffffff;
  background-color: #ff4500;
}

.btn-line-outline {
  color: #00b900;
  border-color: #00b900;
}

.btn-line-outline:hover,
.btn-line-outline:active {
  color: #ffffff;
  background-color: #00b900;
}

/* Fluid Styles */

.fluid {
  display: flex;
}

.fluid a {
  flex-grow: 1;
  margin-right: 0.25rem;
}

.fluid a:last-child {
  margin-right: 0rem;
}
